.launch-container {
    width: 100%;
    height: 100vh;
    background-color: var(--main-color);
    background-size: cover;

    display: grid;
    grid-template-rows: 1.5fr 8fr 6fr;

}

.mob-logo-container {
    display: flex;
    justify-content: center;
}

.mob-logo-container>img {
    width: 200px;
}

.stack-plate {
    backdrop-filter: blur(10px);
    border-radius: 11px;
}

.launch-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
}

.launch-btns {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2vh;
}

.launch-text>h1 {
    font-size: 46px;
    font-weight: 700;
    text-align: center;
    line-height: 60px;
}

.launch-text>p {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    width: 80%;
}

.btn-stack {
    display: flex;
    flex-direction: column;
    padding: 15px;
    color: white;
}

.stack-plate>.stk-btn:first-of-type {
    border-radius: 13px 13px 0px 0px;
    border: none;
}

.stack-plate>.stk-btn:last-of-type {
    border-radius: 0px 0px 13px 13px;
    border: none;
}

.stk-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px;
    background-color: var(--frostedBackground);
    border:
        none;
    ;
}

.stk-plate {
    display: flex;
    align-items: center;
    gap: 20px;
}

.stk-text {
    display: flex;
    align-items: center;
    gap: 20px;
}

.stk-btn-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.stk-btn-text>h2 {
    font-size: 18px;
    font-weight: 600;
}

.stk-btn-text>h3 {
    font-size: 16px;
    font-weight: 600;
}

.stk-btn-text>span {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 20px;
}

.page-content {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 150;
    background-color: var(--main-color);
}

.mob-navbar {
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
}

.back-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: white;
    font-size: var(--px17)
}

.page-content {
    padding: 17px;
    background-size: cover;
    background-repeat: no-repeat;
}

.page-title {
    font-size: 40px;
    color: white;
    font-weight: 700;
    margin-bottom: 25px;
}

.page-desc-text {
    font-size: 13px;
    color: var(--frostedText);
    font-weight: 400;
    margin-bottom: 25px;
    text-align: center;
    padding: 10px 0px;
}

.page-desc-text > a {font-weight: 600;text-decoration: underline;}

.page-desc-text > span {
    font-weight: 600;
    color: white;
}










.stack-divider {width: 100%; display: flex; justify-content: flex-end;}

.divider {width: 90%; height: 1px;background-color: transparent;}












.input-stack {
    display: flex;
    flex-direction: column;
}

.input-stack>input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px;
    background-color: var(--frostedBackground);
    border:
        none;
        border-radius: 0px;
        color: white;

}

.input-stack > input::placeholder {
    color: var(--frostedText);
    font-size: var(--px17);
    font-weight: 400;
}

.input-stack > input:last-of-type {
    border-radius: 0px 0px 13px 13px;

}

.input-stack > input:first-of-type {
    border-radius: 13px 13px 0px 0px;
}

.lg-button {
    background-color: white; border: none; width: 100%; padding: 18px;font-size: var(--px18);
    color: var(--main-color);font-weight: 600; border-radius: 13px;
}

.flat-btn {background-color: transparent; border: none;color: white;margin-top: -10px;}

.form-styling {display: flex; flex-direction: column; gap: 17px;}









.btn-with-icons {display: flex; justify-content: center;align-items: center; padding: 18px;width: 100%;gap: 10px;background-color: var(--frostedBackground);border: none;border-radius: 13px;color: white;font-weight:600;font-size: var(--px18);}

.page-subTitle {    width: 100%;
    display: inline-block;
    gap: 6px;
    color: white;
    padding: 15px 0px;
    margin-top: -15px;
    /* border-bottom: var(--frostedBorder); */
    margin-bottom: 15px;
 
    opacity: 0.7;}

    .page-subTitle > p {font-weight: 600;}


    