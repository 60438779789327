@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800);

:root {
    --main-color: #000000;
    --base-gray: #f1f1f1;
    --off-gray: #e9e9e9;
    --item-gray: #9D9D9D;
    --text-gray: #5f6368;
    --trans: #ffffff00;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --px18: 1.125rem;
    --px19: 1.1875rem;
    --px20: 1.25rem;
    --titleFont: 2.2rem;
    --hoverBorder: rgb(231, 231, 231);
    --hoverBorder: rgba(231, 231, 231, 0.068);
    --frostedBorder: solid 1.5px rgba(255, 255, 255, 0.3);
    --heroFont: 3.6rem;
    --frostedBackground: rgba(255, 255, 255, 0.071);
    --gradientedFrostedBorder: solid 1.5px;
    --frostedText: rgba(255, 255, 255, 0.7);
}

* {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif !important;
    color: var(--main-text);
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

}

button {
    cursor: pointer;
}

a {
    text-decoration: none;
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.modal-pill {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 0px;
    z-index: 9999
}

.modal-pill-inner {
    width: 45px;
    height: 5px;
    background-color: #d9d9d9;
    border-radius: 150px;
}

.modal-title {
    font-size: 31px;
    font-weight: 700;
    max-width: 530px;
    line-height: 38px;
}

.modal-content>img {
    width: 100%;
    margin-top: 21px;
    border-radius: 8px;
}

.nav-close {

    right: 18px;
    top: 18px;
    background-color: var(--base-gray);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 160;
    border: none;
}

.nav-close-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 150;
    left: 0px;
    padding-right: 20px;
    top: 20px;
}

.nav-close>i {
    color: var(--main-color)
}

.modal-screen {
    background-color: var(--base-black),
}


.ReactSwipeableBottomSheet--open {
    background-color: white !important;
    position: relative;
    z-index: 150;
}

.modal-article {
    margin-top: 50px;
}


.material-icons-rounded {
    font-family: 'Material Icons Round' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 2.2rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

* {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    scroll-behavior: smooth;
}

.nav-left>img {
    width: 180px;
    transition: 0.2s ease;
}

nav {
    display: flex !important;
    align-items: center;
    width: 100%;
    padding: 25px 50px;
    justify-content: space-between;
    position: fixed;
    transition: 0.2s ease;
    z-index: 155;
    top: 0px;
}

.refresh-icon {
    position: absolute;
    right: 19px;
    top: 28px;
    padding: 12px;
    background-color: transparent;
    color: white;
    display: none;
    border: none;
}

.refresh-icon>i {
    font-size: 33px;
}

.nav-left {
    display: flex;
    gap: 50px;
    align-items: center;
}

.nav-left>ul {
    display: flex;
    list-style-type: none;
    color: white;
    gap: 30px;
    font-weight: 600;
    text-transform: uppercase;
}

.nav-left>ul>li>a {
    font-size: var(--px14);
}

.vert-spacer {
    height: 20vh;
}

.hero-banner {
    width: 100%;
}

.hero-banner>video {
    width: 100%;
    height: 69vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(0.7);
}

.hero-banner>img {
    width: 100%;
    height: 69vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(0.7);
}

.hero-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 10%;
    height: 69vh;
    max-width: 1192px;
    margin: auto;
    color: white;
    gap: 10px;

}

.hero-text>h1 {
    font-size: 45px;
    font-weight: 700;
    margin-top: 50px;
    max-width: 800px;
    line-height: 55px;
}

.hero-text>p {
    font-size: 20px;
    max-width: 500px;
}

.btn-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.btn {
    color: white;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: var(--frostedBorder);
    background-color: var(--frostedBackground);
    font-size: var(--px15);
    font-weight: 600;
    padding: 13px 25px;
    min-width: 170px;
    border-radius: 10px;

}

.btn-group>.btn:first-of-type {
    background-color: white;
    color: black;
}





.about-us {
    display: flex;
    align-items: center;
    padding: 86px 120px;
    background-repeat: no-repeat;
    background-position: -38px 175px;
    background-size: contain;
    justify-content: center;
    gap: 90px;
}

.about-us-img>img {
    width: 600px;
    border-radius: 15px;
}

.about-us-text>h2 {
    font-weight: 700;
    font-size: 30px;
    color: var(--main-color);
    max-width: 480px;
}

.about-us-text>p {
    line-height: 35px;
    color: var(--text-gray);
    margin-top: 10px;
}

.scrolled {
    background-color: white;
}

.img-view>img {
    width: 310px
}

.tab-sect {
    display: flex;
    padding: 0px 120px;
    background-color: var(--main-color);
    height: 530px;
    align-items: center;
    gap: 90px;
    color: white;
}

.tab-strip {
    display: flex;
    gap: 20px;
    border-bottom: var(--frostedBorder);
    padding-bottom: 16px;
}

.tab-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tab-content>h2 {
    font-weight: 700;
    font-size: 30px;
    color: white;
    margin-bottom: 15px;
}

.tab-item>input:checked~label {
    color: white;
    border-bottom: solid 2.5px white;
}

.tab-item>label {
    font-weight: 600;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.761);
    padding-bottom: 15px;
    border-bottom: solid 2.5px transparent;
    cursor: pointer;
}

.tab-item>input {
    display: none
}

.tab-results {
    margin-top: 50px;
}

.tab-result {
    display: flex;
    flex-direction: column;
    width: 600px;
    ;
}

.tab-result>h2 {
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 15px;
}

.tab-result>p {
    font-size: 17px;
    font-weight: normal;
    line-height: 35px;
}

.new-campaign {
    display: flex;
    justify-content: center;
    height: fit-content;
}

.new-campaign-container {
    padding: 50px;
}

.new-campaign-video>video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.camp-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    text-align: center;
}

.camp-text>h1 {
    font-size: 40px;
}

.mantine-Switch-track {
    cursor: pointer;
}

.mantine-Stepper-separator {
    opacity: 0;
}

.camp-text>p {
    font-size: var(--px16);
    color: var(--text-gray);
}

.input-grouper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

















.lg-article {
    width: 100%;
    height: 75vh
}


.lg-article>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.4);
    position: absolute;
    z-index: -1;
}


.lg-article-text {
    z-index: 15;
    cursor: pointer;
}



.lg-article-text>h1 {
    color: white;
    font-size: 50px;
    line-height: 60px;
}

.lg-article-text>p {
    color: white;
    font-size: var(--px16);
}

.lg-article-text>h3 {
    color: white;
    font-size: var(--px16);
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
}

.lg-article-text {
    width: 50%;
    text-align: left;
    height: 76vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;
    gap: 10px;
}





.mantine-1ymmryr {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 15px;
    width: 30px;
}


.mantine-1ymmryr>svg {
    width: 2rem !important;
    height: 2rem !important;
}







.music-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 58px;
}

.music-player {
    display: flex;
    color: black;
    background-color: white;
    align-items: flex-start;
    height: fit-content;
    padding: 0px 10px;
    border-radius: 10px;
    justify-content: flex-start;
    box-shadow: 0px 1px 33px 0px rgba(0, 0, 0, 0.25);
    flex-direction: column;
}

.music-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.music-text {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: center;

}

.music-text>h3 {
    font-size: var(--px15);
    font-weight: 600;
    margin-bottom: -5px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.music-text>p {
    font-size: var(--px12);
    color: var(--text-gray);
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.music-left>img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
}


.music-right>button {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    height:
        100%;
    border: none;
    cursor: pointer;
    ;
    padding-right: 10px;
}

.music-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loader-icon {
    width: 30px;
    ;
    object-fit: scale-down;
    object-position: center;
    margin-right: 10px;
}

.music-carousel-wrap {
    width: 300px;
}

.music-activity {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
}

.activity-button {
    display: flex;
    width: 100%;
    background-color: transparent;
    align-items: center;
    gap: 15px;
    padding: 10px;
    border: none;
    display: flex;
    ;
    align-items: center;
    justify-content: space-between;
}

.btn-text {
    display: flex;
    gap: 10px;
    align-items: center;
}

.activity-button>i {
    color: var(--text-gray);
}

.btn-text>p {
    font-size: var(--px14);
    font-weight: 600;
}







.social-icons {
    display: flex;
    gap: 15px;
}

.social-icons>a>img {
    width: 25px;
    transition: 0.2s ease;
}

.social-icons>a>img:hover {
    opacity: 0.6;
}

.nav-right {
    display: flex;
    align-items: center;
    gap: 30px;
}

.nav-right>div {
    display: flex;
    align-items: center;
}


.lg-gradient {
    display: none;
}



.article-gradient {
    height: 400px;
    position: absolute;
    margin-bottom: -400px;
    z-index: 15;
    width: 100%;
    border-radius: 14px;
}

.contentItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0px;
    padding: 20px;
    margin-bottom: 20px;
}

.article-card {
    width: 100%;
    height: 400px;
    border-radius: 14px;
    padding: 15px;
}

.article-card>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 14px;
    filter: brightness(0.5);
    border-radius: 15px;
}

.article-text {
    z-index: 145;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    height: 400px;
    padding: 25px;
    margin-top: -400px;
    position: relative;
    cursor: pointer;
}

.article-text>h3 {
    color: white;
    font-size: 26px;
    line-height: 35px;
}

.article-text>p {
    color: white;
    font-size: var(--px15);
}





.article-post-img {
    width: 60%;
    object-fit: cover;
    height: auto;
    border-radius: 15px;
}

.article-post {
    margin-top: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.article-post-text {
    margin: auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.article-post-text>h1 {
    padding: 35px 0px;
}

.article-post-text>p>p>a {
    color: var(--main-color);
    font-weight: 600;
    text-decoration: underline;
}

.article-post-text>p {
    line-height: 26px;
    font-size: var(--px15);
}

.article-post-text>p>h3 {
    margin-bottom: 10px;
    margin-top: 50px;
}

.article-post-text>p>p {
    margin-bottom: 40px;
}

.article-post-text>p>p>img {
    width: 100%;
    border-style: none;
    height: auto;
}





.chat-iframe {
    width: 100%;
    border: none;
    ;
    height: 100% !important;
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 0px 26px;
    padding-bottom: 15px;
    z-index: 20;
}

.chat-header {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background-color: white;
    height: 100px;
    z-index: 160;
    padding: 40px;
    backdrop-filter: blur(10px);

}


.chat-modal {
    display: flex;
    flex-direction: column;
}

.chat-title {}

.mantine-1q36a81 {
    height: 100vh;
}

.mantine-Drawer-header {
    display: none;
}




@media screen and (max-width: 1030px) {

    .chat-header {
        padding: 60px 20px;
    }

    .chat-iframe {
        padding: 0px 13px;
    }

    .contentItem>.article-card:first-of-type {
        margin-top: 0px;
        padding-top: 0px;
    }

    .article-card {
        padding-bottom: 0px;
        ;
    }

    .article-post-img {
        width: 100%;
        border-radius: 0px;
    }

    .article-post-text>h1 {
        padding: 0px;
    }

    .article-post {
        margin-top: 0px;
        position: relative;
        z-index: 800;
    }

    .article-post-text {
        width: 80%;
        margin-top: 10%;
    }

    .music-player {
        margin: 15px;
    }


    .article-gradient {
        border-radius: 0px;
    }

    nav {
        position: absolute;
    }

    .nav-right>div {
        display: none;
    }

    .contentItem {
        grid-template-columns: 1fr !important;
        margin-top: -45px;
        padding: 0px;
        position: relative;
        z-index: -1px;
    }

    .social-icons {
        display: none !important;
    }

    .music-carousel-wrap {
        width: 280px;
    }

    .contentItem>div:first-of-type {
        position: sticky;
        top: 10px;
        z-index: 150;
        display: block;
    }

    .lg-article {
        height: 530px;
    }

    #root>.mantine-7c7vou {
        background-color: black;
        position: sticky;
        top: -487px;
        z-index: 150;
    }

    .music-player {
        position: relative;
        z-index: 15;
    }

    .lg-article>img {
        border-radius: 25px 25px 0px 0px;
        object-fit: cover;
    }


    .lg-article>img {
        width: 97%;
        filter: brightness(0.5);
    }

    .mantine-Carousel-indicators {
        margin-bottom: 50px;
    }

    .lg-gradient {
        display: block;
    }

    .modal-content>img {
        height: 300px;
        object-fit: cover;
    }


    .lg-article-text {
        width: 100%;
        margin-left: 0px;
        text-align: center;
        align-items: center;
        gap: 0px;
        padding: 15px;
        justify-content: flex-end;
        height: 525px;
        position: relative;
        z-index: 151;
    }

    .lg-article-text>h1 {
        color: white;
        font-size: 34px;
        cursor: pointer;
        line-height: 40px;
    }


    .lg-article-text>p {
        margin-bottom: 90px;
        cursor: pointer;
    }

    .lg-gradient>img {
        border-style: none;
        vertical-align: middle;
        background-color: transparent;
        position: absolute;
        z-index: 150;
        top: 0px;
        width: 100%;
        height: 530px;
    }



    .nav-right>div {
        position: absolute;
        top: 488px;
        width: 100%;
        left: 0px;
        z-index: 15;
        padding: 15px;
    }

    .music-text>h3 {
        width: 220px;
    }

    .refresh-icon {
        display: block;
    }

    .music-text>p {
        width: 140px;
    }

    .music-left {
        width: fit-content;
    }

    .nav-left>ul {
        display: none;
    }

    nav>button {
        display: none;
    }

    nav {
        justify-content: center;
    }

    .mantine-Carousel-control {
        display: none;
    }

    .hero-text {
        height: 100vh;
        gap: 25px;
        padding: 0px 5%;
    }

    .hero-text>h1 {
        font-size: 35px
    }

    .hero-text>p {
        font-size: var(--px16);
    }

    .hero-banner>video {
        height: 100vh;
        object-position: right;
    }

    .btn-group {
        flex-direction: column;
    }

    .btn-group {
        width: 100%;
    }

    .btn-group>.btn {
        width: 100%;
    }

    .new-campaign-container {
        padding: 0px 5%;
        margin-top: 20px;
        margin-bottom: 40px;
        width: 100%;
    }

    .new-campaign {
        background-image: none !important;
        height: fit-content !important;
    }

    .camp-text>h1 {
        line-height: 55px;
    }

    .camp-text {
        display: none;
    }
}



.mantine-63haj9 {
    padding: 19px 22px;
    border-radius: 13px;
}

.mantine-63haj9::before {
    display: none !important;
}

.mantine-1wku4s5 {
    font-size: var(--px16);
    font-weight: 600;
}

.mantine-14zcsvh {
    margin-bottom: 50px;
}

.mantine-12vi3x0 {
    font-size: var(--px15);
}